<template>
  <div class="reporter">
    <div class="_title">大会报告人</div>
    <div class="sub_title">（按姓氏汉语拼音排序）</div>
    <v-row class="mt-10">
      <v-col
          v-for="(item, i) in list"
          :key="i"
          class="li mt-4"
          cols="12"
          xl="6"
          xs="12"
          sm="12"
          md="6"
      >
        <div class="avatar">
          <img :src=item.imgSrc />
        </div>
        <div class="_info">
          <p>{{ item.name }}</p>
          <p>{{ item.unitName }}</p>
          <p>{{ item.title }}</p>
        </div>
      </v-col>

    </v-row>
  </div>
</template>

<script>
import {reporterList} from "../../data/d2023";

export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.list = reporterList
  },
  methods: {
    getList() {
      this.$request({
        url: "/report/get",
        method: "get",
      }).then((res) => {
        this.list = res.data;
      });
    },
  },
  mounted() {
  },
};
</script>

<style lang="less" scoped>
.reporter {
  padding: 0 50px;
  padding-bottom: 150px;

  ._title {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
  }
  .sub_title {
    text-align: center;
    font-size: 14px;
    color:#666;
  }

  .li {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .avatar {
      flex: 2;
      height: 160px;

      img {
        width: 130px;
        height: auto;
      }
    }

    ._info {
      flex: 3;
      margin-left: 20px;
      margin-top: 20px;
    }
  }
}
</style>
